<template>
	<router-view> </router-view>
</template>

<script>
import { mapActions, mapGetters } from "vuex"

export default {
	// add event listener that listens for showSidebar changes and toggles sidebar across mobile and desktop devices accordingly
	mounted() {
		this.toggleSidebar()
	},
	methods: {
		...mapActions(["setShowSidebar"]),
		toggleSidebar() {
			window.addEventListener("resize", this.toggleSidebar)
			if (window.innerWidth < 768) {
				this.setShowSidebar(false)
			} else {
				this.setShowSidebar(true)
			}
		}
	},
	computed: {
		...mapGetters(["showSidebar"])
	}
}
</script>

<style lang="scss">
#app {
	height: 100%;
	font-family: Calibri, Roboto, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

html {
	background-color: hsl($background-h, $background-s, 95%);
}

html,
body {
	height: 100%;
	font-size: 16px;
}
</style>
