import { createStore } from "vuex"
import initialState from "@/store/state.js"
import rootActions from "@/store/actions.js"
import rootMutations from "@/store/mutations.js"
import rootGetters from "@/store/getters.js"

export default createStore({
	state: initialState(),
	mutations: rootMutations,
	actions: rootActions,
	getters: rootGetters
})
