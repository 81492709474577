const initialState = () => {
	return {
		isLoggedIn: false,
		userData: {},
		uploadedDocuments: {},
		roles: localStorage.getItem("userRoles") || [],
		designation: localStorage.getItem("designation") || "",
		userType: localStorage.getItem("userType") || "",
		accessToken: localStorage.getItem("accessToken") || null,
		nominees: [],
		colorScheme: {
			user: {
				hue: 135
			},
			admin: {
				hue: 204
			},
			default: {
				hue: 200
			}
		},
		showSidebar: false
	}
}

export default initialState
