export default {
	isLoggedIn: state => state.isLoggedIn,
	userData: state => state.userData,
	uploadedDocuments: state => state.uploadedDocuments,
	roles: state => state.roles,
	designation: state => state.designation,
	userType: state => state.userType,
	accessToken: state => state.accessToken,
	nominees: state => state.nominees,
	colorScheme: state => state.colorScheme,
	showSidebar: state => state.showSidebar
}
