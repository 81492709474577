import { createRouter, createWebHistory } from "vue-router"
import store from "@/store/store"

const routes = [
	{
		path: "/",
		name: "home",
		component: () => import(/* webpackChunkName: "home-view" */ "../views/HomeView.vue"),
		meta: { requiresAuth: true, userType: ["USER", "KDA_OFFICER"], title: "Kanpur Development Authority" },
		children: [
			{
				path: "user/dashboard",
				name: "userDashboard",
				component: () => import(/* webpackChunkName: "user-dashboard" */ "../views/user/UserDashboard.vue"),
				meta: { requiresAuth: true, userType: ["USER"], title: "Dashboard" }
			},
			{
				path: "user/profile",
				name: "userProfile",
				component: () => import(/* webpackChunkName: "user-profile" */ "../views/ProfileView.vue"),
				meta: { requiresAuth: true, userType: ["USER", "KDA_OFFICER"] }
			},
			//  user-payment
			{
				path: "user/payment",
				name: "UserPayment",
				component: () => import(/* webpackChunkName: "user-payment" */ "../views/user/UserPayment.vue"),
				meta: { requiresAuth: true, userType: ["USER"] }
			},
			{
				path: "user/tdr",
				name: "userTdr",
				redirect: {
					name: "userViewBaseTDR"
				},
				meta: { requiresAuth: true, userType: ["USER"], title: "TDR" },
				component: () => import(/* webpackChunkName: "tdr" */ "../views/user/TDR/UserBaseTDR.vue"),
				children: [
					{
						path: "view",
						name: "userViewBaseTDR",
						component: () => import(/* webpackChunkName: "base-tdr" */ "../views/user/TDR/UserTDR.vue"),
						meta: { requiresAuth: true, userType: ["USER"] }
					},
					{
						path: "apply",
						name: "userApplyTDR",
						component: () => import(/* webpackChunkName: "apply-tdr" */ "../views/user/TDR/UserApplyTDR.vue"),
						meta: { requiresAuth: true, userType: ["USER"] }
					},
					{
						path: ":appId/view",
						name: "userViewTDR",
						component: () => import(/* webpackChunkName: "view-tdr" */ "../views/user/TDR/UserViewTDR.vue"),
						meta: { requiresAuth: true, userType: ["USER"] }
					}
				]
			},
			{
				path: "user/tdr-notice",
				name: "userTDRNotice",
				redirect: {
					name: "userTdrNotice"
				},
				meta: { requiresAuth: true, userType: ["USER"], title: "TDR Notice" },
				component: () => import(/* webpackChunkName: "tdr" */ "../views/user/TDRNotice/UserBaseTDRNotice.vue"),
				children: [
					{
						path: "corrections",
						name: "userTdrNoticeCorrection",
						component: () =>
							import(/* webpackChunkName: "base-tdr" */ "../views/user/TDRNotice/UserTDRNoticeCorrection.vue"),
						meta: { requiresAuth: true, userType: ["USER"] }
					},
					{
						path: "view",
						name: "userTdrNotice",
						component: () => import(/* webpackChunkName: "base-tdr" */ "../views/user/TDRNotice/UserTDRNotice.vue"),
						meta: { requiresAuth: true, userType: ["USER"] }
					}
				]
			},
			{
				path: "user/drc",
				name: "userDrc",
				redirect: {
					name: "userViewBaseDRC"
				},
				meta: { requiresAuth: true, userType: ["USER"] },
				component: () => import(/* webpackChunkName: "drc" */ "../views/user/DRC/UserBaseDRC.vue"),
				children: [
					{
						path: "view",
						name: "userViewBaseDRC",
						component: () => import(/* webpackChunkName: "base-drc" */ "../views/user/DRC/UserDRC.vue"),
						meta: { requiresAuth: true, userType: ["USER"] }
					},
					{
						path: ":appId/apply",
						name: "userApplyDRC",
						component: () => import(/* webpackChunkName: "apply-drc" */ "../views/user/DRC/UserApplyDRC.vue"),
						meta: { requiresAuth: true, userType: ["USER"] }
					},
					{
						path: "utilization/:duaId/view",
						name: "userViewDRCU",
						component: () => import(/* webpackChunkName: "apply-drc" */ "../views/user/DRC/UserViewDRCU.vue"),
						meta: { requiresAuth: true, userType: ["USER"] }
					},
					{
						path: "transfer/:dtaId/view",
						name: "userViewDRCT",
						component: () => import(/* webpackChunkName: "apply-drc" */ "../views/user/DRC/UserViewDRCT.vue"),
						meta: { requiresAuth: true, userType: ["USER"] }
					}
				]
			},
			{
				path: "nominations",
				name: "UserNominationsBase",
				redirect: {
					name: "UserNominationsBaseView"
				},
				meta: { requiresAuth: true, userType: ["USER"] },
				component: () =>
					import(/* webpackChunkName: "user-nominations-base" */ "../views/user/nominations/UserNominationsBase.vue"),
				children: [
					{
						path: "view",
						name: "UserNominationsBaseView",
						component: () =>
							import(
								/* webpackChunkName: "user-nominations-base-view" */ "../views/user/nominations/UserNominationsBaseView.vue"
							),
						meta: { requiresAuth: true, userType: ["USER"] }
					},
					{
						path: "add",
						name: "UserNominationsBaseAdd",
						component: () =>
							import(
								/* webpackChunkName: "user-nominations-base-add" */ "../views/user/nominations/UserNominationsBaseAdd.vue"
							),
						meta: { requiresAuth: true, userType: ["USER"] }
					}
				]
			},
			{
				//UserAppointmentsBase
				path: "appointments",
				name: "UserAppointmentsBase",
				redirect: {
					name: "UserAppointmentsBaseView"
				},
				meta: { requiresAuth: true, userType: ["USER"] },
				component: () =>
					import(
						/* webpackChunkName: "user-appointments-base" */ "../views/user/appointments/UserAppointmentsBase.vue"
					),
				children: [
					{
						path: "view",
						name: "UserAppointmentsBaseView",
						component: () =>
							import(
								/* webpackChunkName: "user-appointments-base-view" */ "../views/user/appointments/UserAppointmentsBaseView.vue"
							),
						meta: { requiresAuth: true, userType: ["USER"] }
					},
					{
						path: "add",
						name: "UserAppointmentsBaseAdd",
						component: () =>
							import(
								/* webpackChunkName: "user-appointments-base-add" */ "../views/user/appointments/UserAppointmentsBaseAdd.vue"
							),
						meta: { requiresAuth: true, userType: ["USER"] }
					}
				]
			},
			{
				path: "admin/dashboard",
				name: "adminDashboard",
				component: () => import("@/views/admin/AdminDashboard.vue"),
				meta: { requiresAuth: true, userType: ["KDA_OFFICER"] } // only admin role can access
			},
			{
				path: "admin/user-management",
				name: "adminUserManagement",
				redirect: {
					name: "adminBaseViewUserManagement"
				},
				meta: { requiresAuth: true, userType: ["KDA_OFFICER"] },
				component: () =>
					import(/* webpackChunkName: "tdr" */ "../views/admin/UserManagement/AdminBaseUserManagement.vue"),
				children: [
					{
						path: "view",
						name: "adminBaseViewUserManagement",
						component: () =>
							import(/* webpackChunkName: "base-tdr" */ "../views/admin/UserManagement/AdminUserManagement.vue"),
						meta: { requiresAuth: true, userType: ["KDA_OFFICER"] }
					},
					{
						path: ":userId/view",
						name: "adminViewCitizen",
						component: () =>
							import(/* webpackChunkName: "base-tdr" */ "../views/admin/UserManagement/AdminCitizenView.vue"),
						meta: { requiresAuth: true, userType: ["KDA_OFFICER"] }
					}
				]
			},
			{
				path: "admin/tdr-notice",
				name: "adminTDRNotice",
				redirect: {
					name: "adminBaseViewTDRNotice"
				},
				meta: { requiresAuth: true, userType: ["KDA_OFFICER"] },
				component: () => import(/* webpackChunkName: "tdr" */ "../views/admin/TDRNotice/AdminBaseTDRNotice.vue"),
				children: [
					{
						path: "view",
						name: "adminBaseViewTDRNotice",
						component: () => import(/* webpackChunkName: "base-tdr" */ "../views/admin/TDRNotice/AdminTDRNotice.vue"),
						meta: { requiresAuth: true, userType: ["KDA_OFFICER"] }
					},
					{
						path: "project/:projectId/view",
						name: "adminProjectViewTDRNotice",
						component: () =>
							import(/* webpackChunkName: "base-tdr" */ "../views/admin/TDRNotice/AdminProjectViewTDRNotice.vue"),
						meta: { requiresAuth: true, userType: ["KDA_OFFICER"] }
					},
					{
						path: "project/add",
						name: "adminProjectAddTDRNotice",
						component: () =>
							import(/* webpackChunkName: "base-tdr" */ "../views/admin/TDRNotice/AdminProjectAddTDRNotice.vue"),
						meta: { requiresAuth: true, userType: ["KDA_OFFICER"] }
					},
					{
						path: "property/:propertyId/view",
						name: "adminPropertyViewTDRNotice",
						component: () =>
							import(/* webpackChunkName: "base-tdr" */ "../views/admin/TDRNotice/AdminPropertyViewTDRNotice.vue"),
						meta: { requiresAuth: true, userType: ["KDA_OFFICER"] }
					},
					{
						path: "project/:projectId/property/add",
						name: "adminPropertyAddTDRNotice",
						component: () =>
							import(/* webpackChunkName: "base-tdr" */ "../views/admin/TDRNotice/AdminPropertyAddTDRNotice.vue"),
						meta: { requiresAuth: true, userType: ["KDA_OFFICER"] }
					},
					{
						path: "project/property/:propertyId/create-tdr-notice",
						name: "adminCreateTDRNotice",
						component: () =>
							import(/* webpackChunkName: "base-tdr" */ "../views/admin/TDRNotice/AdminCreateTDRNotice.vue"),
						meta: { requiresAuth: true, userType: ["KDA_OFFICER"] }
					},
					{
						path: ":noticeId/view",
						name: "adminViewTDRNotice",
						component: () =>
							import(/* webpackChunkName: "base-tdr" */ "../views/admin/TDRNotice/AdminViewTDRNotice.vue"),
						meta: { requiresAuth: true, userType: ["KDA_OFFICER"] }
					},
					{
						path: ":noticeId/:applicationId/corrections/view",
						name: "adminViewTDRNoticeCorrection",
						component: () =>
							import(/* webpackChunkName: "base-tdr" */ "../views/admin/TDRNotice/AdminViewTDRNoticeCorrection.vue"),
						meta: { requiresAuth: true, userType: ["KDA_OFFICER"] }
					}
				]
			},
			{
				path: "admin/utilization-register",
				name: "adminUtilizationRegister",
				redirect: {
					name: "adminBaseViewUtilizationRegister"
				},
				meta: { requiresAuth: true, userType: ["KDA_OFFICER"] },
				component: () =>
					import(/* webpackChunkName: "tdr" */ "../views/admin/UtilizationRegister/AdminBaseUtilizationRegister.vue"),
				children: [
					{
						path: "view",
						name: "adminBaseViewUtilizationRegister",
						component: () =>
							import(
								/* webpackChunkName: "base-tdr" */ "../views/admin/UtilizationRegister/AdminUtilizationRegister.vue"
							),
						meta: { requiresAuth: true, userType: ["KDA_OFFICER"] }
					}
				]
			},
			{
				path: "admin/officers",
				name: "adminOfficerManagement",
				redirect: {
					name: "adminBaseViewOfficerManagement"
				},
				meta: { requiresAuth: true, userType: ["KDA_OFFICER"] },
				component: () =>
					import(/* webpackChunkName: "tdr" */ "../views/admin/OfficerManagement/AdminOfficerManagementBase.vue"),
				children: [
					{
						path: "view",
						name: "adminBaseViewOfficerManagement",
						component: () =>
							import(/* webpackChunkName: "base-tdr" */ "../views/admin/OfficerManagement/AdminOfficerManagement.vue"),
						meta: { requiresAuth: true, userType: ["KDA_OFFICER"] }
					},
					{
						path: "add",
						name: "adminOfficerAdd",
						component: () =>
							import(/* webpackChunkName: "base-tdr" */ "../views/admin/OfficerManagement/adminOfficerAdd.vue"),
						meta: { requiresAuth: true, userType: ["KDA_OFFICER"] }
					},
					{
						path: ":officerId/view",
						name: "adminOfficerView",
						component: () =>
							import(/* webpackChunkName: "base-tdr" */ "../views/admin/OfficerManagement/adminOfficerView.vue"),
						meta: { requiresAuth: true, userType: ["KDA_OFFICER"] }
					}
				]
			},
			{
				path: "admin/tdr",
				name: "adminTdr",
				redirect: {
					name: "adminViewBaseTDR"
				},
				meta: { requiresAuth: true, userType: ["KDA_OFFICER"] },
				component: () => import(/* webpackChunkName: "tdr" */ "../views/admin/TDR/AdminBaseTDR.vue"),
				children: [
					{
						path: "view",
						name: "adminViewBaseTDR",
						component: () => import(/* webpackChunkName: "base-tdr" */ "../views/admin/TDR/AdminTDR.vue"),
						meta: { requiresAuth: true, userType: ["KDA_OFFICER"] }
					},
					{
						path: "apply",
						name: "adminApplyTDR",
						component: () => import(/* webpackChunkName: "apply-tdr" */ "../views/admin/TDR/AdminApplyTDR.vue"),
						meta: { requiresAuth: true, userType: ["KDA_OFFICER"] }
					},
					{
						path: ":appId/view",
						name: "adminViewTDR",
						component: () => import(/* webpackChunkName: "view-tdr" */ "../views/admin/TDR/AdminViewTDR.vue"),
						meta: { requiresAuth: true, userType: ["KDA_OFFICER"] }
					}
				]
			},
			{
				path: "admin/issue-drc",
				name: "adminIssueDRC",
				redirect: {
					name: "adminViewIssueDRC"
				},
				meta: { requiresAuth: true, userType: ["KDA_OFFICER"] },
				component: () => import(/* webpackChunkName: "tdr" */ "../views/admin/IssueDRC/AdminBaseIssueDRC.vue"),
				children: [
					{
						path: "view",
						name: "adminViewIssueDRC",
						component: () => import(/* webpackChunkName: "base-tdr" */ "../views/admin/IssueDRC/AdminIssueDRC.vue"),
						meta: { requiresAuth: true, userType: ["KDA_OFFICER"] }
					},
					{
						path: ":appId/view",
						name: "adminViewSaledeedTDR",
						component: () => import(/* webpackChunkName: "view-tdr" */ "../views/admin/TDR/AdminViewSaledeedTDR.vue"),
						meta: { requiresAuth: true, userType: ["KDA_OFFICER"] }
					}
				]
			},
			{
				path: "admin/drc",
				name: "adminDrc",
				redirect: {
					name: "adminViewBaseDRC"
				},
				meta: { requiresAuth: true, userType: ["KDA_OFFICER"] },
				component: () => import(/* webpackChunkName: "drc" */ "../views/admin/DRC/AdminBaseDRC.vue"),
				children: [
					{
						path: "view",
						name: "adminViewBaseDRC",
						component: () => import(/* webpackChunkName: "base-drc" */ "../views/admin/DRC/AdminDRC.vue"),
						meta: { requiresAuth: true, userType: ["KDA_OFFICER"] }
					},
					{
						path: ":appId/utilization/view",
						name: "adminViewUtlizationDRC",
						component: () =>
							import(/* webpackChunkName: "apply-drc" */ "../views/admin/DRC/AdminViewUtlizationDRC.vue"),
						meta: { requiresAuth: true, userType: ["KDA_OFFICER"] }
					},
					{
						path: ":appId/transfer/view",
						name: "adminViewTransferDRC",
						component: () => import(/* webpackChunkName: "apply-drc" */ "../views/admin/DRC/AdminViewTransferDRC.vue"),
						meta: { requiresAuth: true, userType: ["KDA_OFFICER"] }
					},
					{
						path: ":appId/drc/:status",
						name: "adminStatusDRC",
						component: () => import(/* webpackChunkName: "apply-drc" */ "../views/admin/DRC/AdminStatusDRC.vue"),
						meta: { requiresAuth: true, userType: ["KDA_OFFICER"] }
					},
					{
						path: ":appId/cancel/issue-order",
						name: "adminCancelIssueOrderDRC",
						component: () =>
							import(/* webpackChunkName: "apply-drc" */ "../views/admin/DRC/AdminCancelIssueOrderDRC.vue"),
						meta: { requiresAuth: true, userType: ["KDA_OFFICER"] }
					},
					{
						path: ":appId/cancel/process-order",
						name: "adminCancelProcessOrderDRC",
						component: () =>
							import(/* webpackChunkName: "apply-drc" */ "../views/admin/DRC/AdminCancelProcessOrderDRC.vue"),
						meta: { requiresAuth: true, userType: ["KDA_OFFICER"] }
					},
					{
						path: ":appId/cancel/revert-order",
						name: "adminCancelRevertOrderDRC",
						component: () =>
							import(/* webpackChunkName: "apply-drc" */ "../views/admin/DRC/AdminCancelRevertOrderDRC.vue"),
						meta: { requiresAuth: true, userType: ["KDA_OFFICER"] }
					}
				]
			},
			{
				path: "admin/nominations",
				name: "adminNominations",
				redirect: {
					name: "adminViewBaseNominations"
				},
				meta: { requiresAuth: true, userType: ["KDA_OFFICER"] },
				component: () => import(/* webpackChunkName: "drc" */ "../views/admin/Nominations/AdminBaseNominations.vue"),
				children: [
					{
						path: "",
						name: "adminViewBaseNominations",
						component: () =>
							import(/* webpackChunkName: "base-drc" */ "../views/admin/Nominations/AdminNominations.vue"),
						meta: { requiresAuth: true, userType: ["KDA_OFFICER"] }
					},
					{
						path: "add",
						name: "adminAddNomination",
						component: () =>
							import(/* webpackChunkName: "base-drc" */ "../views/admin/Nominations/AdminAddNomination.vue"),
						meta: { requiresAuth: true, userType: ["KDA_OFFICER"] }
					},
					{
						path: ":appId/view",
						name: "adminViewNomination",
						component: () =>
							import(/* webpackChunkName: "apply-drc" */ "../views/admin/Nominations/AdminViewNomination.vue"),
						meta: { requiresAuth: true, userType: ["KDA_OFFICER"] }
					}
				]
			},
			{
				path: "admin/appointments",
				name: "adminAppointments",
				redirect: {
					name: "adminViewBaseAppointments"
				},
				meta: { requiresAuth: true, userType: ["KDA_OFFICER"] },
				component: () => import(/* webpackChunkName: "drc" */ "../views/admin/Appointments/AdminBaseAppointments.vue"),
				children: [
					{
						path: "",
						name: "adminViewBaseAppointments",
						component: () =>
							import(/* webpackChunkName: "base-drc" */ "../views/admin/Appointments/AdminAppointments.vue"),
						meta: { requiresAuth: true, userType: ["KDA_OFFICER"] }
					}
				]
			},
			{
				path: "admin/certificates",
				name: "adminCertificates",
				redirect: {
					name: "adminBaseCertificates"
				},
				meta: { requiresAuth: true, userType: ["KDA_OFFICER"] },
				component: () => import(/* webpackChunkName: "drc" */ "../views/admin/Certificates/AdminBaseCertificates.vue"),
				children: [
					{
						path: "",
						name: "adminBaseCertificates",
						component: () =>
							import(/* webpackChunkName: "base-drc" */ "../views/admin/Certificates/AdminCertificates.vue"),
						meta: { requiresAuth: true, userType: ["KDA_OFFICER"] }
					}
				]
			},
			// flows
			{
				path: "workflow/:workflowType",
				name: "workflowBase",
				meta: { requiresAuth: true, userType: ["KDA_OFFICER"] },
				component: () => import(/* webpackChunkName: "admin-base-flows" */ "../views/admin/Flows/AdminWorklowBase.vue")
			}
		]
	},
	{
		path: "/login",
		name: "login",
		component: () => import(/* webpackChunkName: "login" */ "../views/LoginBaseView.vue")
	},
	{
		path: "/sign-up",
		name: "signup",
		component: () => import(/* webpackChunkName: "signup" */ "../views/LoginBaseView.vue")
	}
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
})

router.beforeEach(async (to, from, next) => {
	const isAuthenticated = localStorage.getItem("isLoggedIn")
	const usertype = (await store?.getters?.userType) || (await store?.getters?.userType)

	// set tab title
	const title = to.meta.title

	if (to.name == "home") document.title = title
	if (title) document.title = title + " | Kanpur Development Authority"
	if (!title) document.title = "Kanpur Development Authority"

	if (to.meta.requiresAuth && !isAuthenticated) next("/login")
	if (to.meta.userType && !to.meta.userType.includes(usertype)) next("/")
	if (to.name == "login" && isAuthenticated) next({ name: getRoute(usertype) })
	else next()
})

const getRoute = usertype => {
	return usertype == "USER" ? "userDashboard" : "adminDashboard"
}

export default router
