import { createApp } from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store/store"
import axios from "axios"

const accessToken = localStorage.getItem("accessToken")
axios.defaults.headers.Authorization = router.currentRoute.value.name == "login" ? "" : `Bearer ${accessToken}`
axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL

// custom js
import "@/assets/js/icons.js"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"

const app = createApp(App)

app.use(store).use(router).component("icon", FontAwesomeIcon)
app.mount("#app")
