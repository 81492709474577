import { library } from "@fortawesome/fontawesome-svg-core"
import {
	faCircle,
	faCircleInfo,
	faWindowMaximize,
	faPlus,
	faPlusCircle,
	faEnvelope,
	faMobile,
	faHouse,
	faAngleDown,
	faCircleCheck,
	faUser,
	faCalendar,
	faIdCard,
	faHome,
	faUpload,
	faChevronLeft,
	faChevronRight,
	faChevronDown,
	faEye,
	faAngleDoubleLeft,
	faAngleDoubleRight,
	faAddressCard,
	faTimesCircle,
	faTimes,
	faForward,
	faDownload,
	faBackward,
	faCircleXmark,
	faCircleQuestion,
	faTriangleExclamation,
	faCopy,
	faClone,
	faCheck,
	faTrash,
	faMinus,
	faBan,
	faRedo,
	faUserPen,
	faPen,
	faBell,
	faFileExcel,
	faFilePdf,
	faDashboard,
	faFileSignature,
	faCertificate,
	faCheckSquare,
	faCalendarCheck,
	faUsers,
	faUserTie,
	faFileAlt,
	faUserPlus,
	faComments,
	faFileEdit,
	faBars,
	faTable
} from "@fortawesome/free-solid-svg-icons"
// import { faBell } from "@fortawesome/free-regular-svg-icons"

library.add(
	faCircle,
	faBell,
	faCircleInfo,
	faWindowMaximize,
	faPlus,
	faPlusCircle,
	faEnvelope,
	faMobile,
	faHouse,
	faAngleDown,
	faCircleCheck,
	faUser,
	faCalendar,
	faIdCard,
	faHome,
	faUpload,
	faChevronLeft,
	faChevronRight,
	faChevronDown,
	faEye,
	faAngleDoubleLeft,
	faAngleDoubleRight,
	faAddressCard,
	faTimesCircle,
	faTimes,
	faForward,
	faBackward,
	faDownload,
	faCircleXmark,
	faCircleQuestion,
	faTriangleExclamation,
	faCopy,
	faClone,
	faCheck,
	faTrash,
	faMinus,
	faBan,
	faRedo,
	faUserPen,
	faPen,
	faBell,
	faFileExcel,
	faFilePdf,
	faDashboard,
	faFileSignature,
	faCertificate,
	faCheckSquare,
	faCalendarCheck,
	faUsers,
	faUserTie,
	faFileAlt,
	faUserPlus,
	faComments,
	faFileEdit,
	faBars,
	faTable
)
