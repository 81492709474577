import axios from "axios"

export default {
	setLoginState({ commit }, state) {
		if (!state) {
			commit("SET_USER_DATA", {})
			commit("SET_USER_DESIGNATION", "")
			commit("SET_USER_TYPE", "")
			commit("SET_USER_ROLES", [])
			commit("SET_UPLOADED_DOCUMENTS", "")
		}
		commit("SET_LOGIN_STATE", state)
		localStorage.setItem("isLoggedIn", state)
	},
	setUserData({ commit }, userData) {
		commit("SET_USER_DATA", userData)
	},
	setUserDocuments({ commit }, documents) {
		commit("SET_UPLOADED_DOCUMENTS", documents)
	},
	setRoles({ commit }, roles) {
		localStorage.setItem("userRoles", roles)
		commit("SET_USER_ROLES", roles)
	},
	setDesignation({ commit }, designation) {
		localStorage.setItem("designation", designation)
		commit("SET_USER_DESIGNATION", designation)
	},
	setUserType({ commit }, userType) {
		localStorage.setItem("userType", userType)
		commit("SET_USER_TYPE", userType)
	},
	setAccessToken({ commit }, accessToken) {
		localStorage.setItem("accessToken", accessToken)
		commit("SET_ACCESS_TOKEN", accessToken)
		axios.defaults.headers.Authorization = `Bearer ${accessToken}`
	},
	setNominee({ commit }, nominees) {
		commit("SET_NOMINEE", nominees)
	},
	setShowSidebar({ commit }, status) {
		commit("SET_SHOW_SIDEBAR", status)
	},
	setUserPhoto({ commit }, photo) {
		commit("SET_USER_PHOTO", photo)
	}
}
