export default {
	SET_LOGIN_STATE: (state, status) => (state.isLoggedIn = status),
	SET_USER_DATA: (state, userData) => (state.userData = userData),
	SET_UPLOADED_DOCUMENTS: (state, documents) => (state.uploadedDocuments = documents),
	SET_USER_ROLES: (state, roles) => (state.roles = roles),
	SET_USER_DESIGNATION: (state, designation) => (state.designation = designation),
	SET_USER_TYPE: (state, userType) => (state.userType = userType),
	SET_ACCESS_TOKEN: (state, accessToken) => (state.userType = accessToken),
	SET_NOMINEE: (state, nominees) => (state.nominees = nominees),
	SET_SHOW_SIDEBAR: (state, status) => (state.showSidebar = status),
	SET_USER_PHOTO: (state, photo) => (state.userData.data.photo = photo)
}
